import {getAppointCourseInfo, getTeacherList} from "@/api/api";
import {getAllLaboratory} from "@/api/examination";
import dayjs from "dayjs";

const dataProcessing = {
    scopeSplitting: (str, handler) => {
        const range = str.split('-')
        const start = parseInt(range[0])
        const end = parseInt(range[1])
        if (isNaN(start) || isNaN(end)) {
            throw new Error(`表格中周次填写错误, ${str}`)
        }
        for (let i = start; i <= end; i++) {
            handler(i)
        }
    },
    courseSplitting: (str) => {
        const range = str.replaceAll('节', '').split(',')
        const result = []
        range.forEach(iter => {
            if (iter.includes('-')) {
                const inner = iter.split('-')
                const start = parseInt(inner[0])
                const end = parseInt(inner[1])
                if (isNaN(start) || isNaN(end)) {
                    throw new Error(`表格中节次填写错误, ${str}`)
                }
                for (let i = start; i <= end; i++) {
                    result.push(i)
                }
            } else {
                const start = parseInt(iter)
                if (isNaN(start)) {
                    throw new Error(`表格中节次填写错误, ${str}`)
                }
                result.push(start)
            }
        })
        return result
    }
}

class WeekItem {
    constructor(start) {
        this.weekly = 0
        this.weeks = []
        this.startDate = dayjs(start)
        this.generateWeeks()
    }

    findDay(day, type = 'day'){
        const item = this.weeks.find(iter => iter.day() === day)
        if (item) {
            return type === 'day' ? item.day() : item.format('YYYY-MM-DD')
        }
        return null
    }

    generateWeeks(){
        this.weekly++
        for (let i = 0; i < 7; i++) {
            this.weeks.push(this.startDate)
            this.startDate = this.startDate.add(1, 'day')
        }
    }

    setWeekly(weekly){
        this.weekly = weekly
    }

    generateNextWeekItem(){
        const start = this.startDate.format('YYYY-MM-DD')
        const weekItem = new WeekItem(start)
        weekItem.setWeekly(this.weekly + 1)
        return weekItem
    }
}

class Weekly {
    constructor() {
        this.start = ''
        this.weekItem = []
    }

    generateWeekly(){
        // todo start必须有值
        let weekItem = new WeekItem(this.start)
        for (let i = 0; i < 50; i++) {
            this.weekItem.push(weekItem)
            weekItem = weekItem.generateNextWeekItem()
        }
        // console.log(this.weekItem, '---------this.weekItem-');
    }

    /**
     * 查找第count周的星期day(0-6)
     * @param count
     * @param day
     */
    findDate(count, day){
        const item = this.weekItem.find(iter => iter.weekly === count)
        if (item && item.findDay(day) === day) {
            return item.findDay(day, 'date')
        }
        return null
    }

    setStart(start){
        this.start = start
    }
}

class DataDict{
    constructor(finishHandler) {
        // 教职工
        this.facultys = []
        // 实验室
        this.laboratorys = []
        //
        this.finishHandler = finishHandler

        this.getData()
    }

    getData(){
        Promise.all([getTeacherList(), getAllLaboratory()]).then(res => {
            // 教师
            if (res[0].success) {
                this.facultys = res[0].data
            }
            // 实验室
            if (res[1].success) {
                this.laboratorys = res[1].data
            }
        }).finally(() => {
            this.finishHandler(this)
        })
    }

    /**
     * 查询教职工
     * @param no
     * @returns {string|null}
     */
    findFacultyByCode(no){
        const item = this.facultys.find(iter => String(iter.code) === String(no))
        if (item) {
            return item.id
        }
        return null
    }

    /**
     * 查找实验室
     * @param jwName
     */
    findLaboratoryByJwName(jwName){
        const item = this.laboratorys.find(iter => iter.jwName === jwName)
        if (item) {
            return item.id
        }
        return null
    }
}

class ExcelRowMap {
    constructor(data) {
        const row = data.find(iter => iter['__EMPTY'] === '选课编号')
        if (!row) {
            throw Error('表格标题行不存在!')
        }

        this.titleRow = row
        this.keyMapper = {}
        for (let key in row) {
            const k = row[key].replace(/\s+/g, '')
            this.keyMapper[k] = key
        }
    }

    getKey(key){
        return this.keyMapper[key]
    }

    equalsKey(row){
        // 比较 key
        const rowList = Object.keys(row).sort()
        const titleRowList = Object.keys(this.titleRow).sort()
        // if (rowList.length !== titleRowList.length) {
        //     return false
        // }
        // const result = rowList.every((element, index) => element === titleRowList[index])
        const result = (() => {
            let res = false
            for (let key of rowList) {
                res = titleRowList.includes(key)
            }
            return res
        })()
        if (!result) {
            return false
        }

        // 是否是标题
        if (this.titleRow['__EMPTY'] === row['__EMPTY']) {
            return false
        }

        return true
    }
}

class ExcelRow {
    constructor(row, excelRowMap, dataDict) {
        this.row = row
        this.excelRowMap = excelRowMap
        this.customId = 0

        // 提交字段-
        // 授课班级
        this.className = this.get('优选类别')
        // 实验室id
        this.laboratoryId = dataDict.findLaboratoryByJwName(this.get('地点'))
        // 学生人数
        this.studentNum = this.get('选课人数')
        // 用户类型
        this.userType = 1
        // 教师 id
        this.userId = dataDict.findFacultyByCode(this.get('教师工号'))
        // 导入标识
        this.isAdminExport = true
        // 课程 id
        this.curriculumId = null
        // 日期
        this.date = null
        // 用途
        this.use = this.get('课程名称')

        // 课程节次赋值
        this.getCurriculumId()
    }

    getParams(){
        let { className, laboratoryId, studentNum,
            userType, userId, isAdminExport,
            curriculumId, date, use } = this
        //
        const dateArr = date.split(',')
        const curriculumIdArr = curriculumId.split(',')

        const resultDateArr = []
        const resultCurriculumIdArr = []

        dateArr.forEach(iter => {
            curriculumIdArr.forEach(item => {
                resultCurriculumIdArr.push(item)
                resultDateArr.push(iter)
            })
        })

        return {
            className, laboratoryId, studentNum,
            userType, userId, isAdminExport,
            curriculumId: resultCurriculumIdArr.join(','),
            date: resultDateArr.join(','),
            use,
        }
    }

    /**
     * 简单检测预提交的数据
     */
    verifyData(){
        const reslut = {
            adopt: true,
            message: ''
        }

        // 授课班级
        if (!this.className) {
            reslut.adopt = false
            reslut.message = '表格中缺失优选类别'
            return reslut
        }
        // 实验室id
        if (!this.laboratoryId) {
            reslut.adopt = false
            reslut.message = `表格中的地点:${this.get('地点')}在实验室中不存在`
            return reslut
        }
        // 学生人数
        this.studentNum = this.get('选课人数')
        if (!this.studentNum) {
            reslut.adopt = false
            reslut.message = `表格中缺失选课人数`
            return reslut
        }
        // 教师 id
        if (!this.userId) {
            reslut.adopt = false
            reslut.message = `未查找到表格中的教师工号:${this.get('教师工号')}对应的教师`
            return reslut
        }
        // 课程 id
        if (!this.curriculumId) {
            reslut.adopt = false
            reslut.message = `未查找到实验室对应的课程`
            return reslut
        }
        // 日期
        if (!this.date) {
            reslut.adopt = false
            reslut.message = `请设置开始周日期`
            return reslut
        }
        return reslut
    }

    /**
     * 生成日期
     */
    generationDate(start){
        let weekly = new Weekly()
        weekly.setStart(start)
        weekly.generateWeekly()

        // 日期 = YYYY-MM-DD,YYYY-MM-DD
        // YYYY-MM-DD = 第x周的星期x日期
        const findWeekday = (() => {
            const weekMapper = {
                '星期一': 1,
                '星期二': 2,
                '星期三': 3,
                '星期四': 4,
                '星期五': 5,
                '星期六': 6,
                '星期日': 0,
            }
            const column = this.get('星期')
            const result = weekMapper[column]
            if (![0, 1, 2, 3, 4, 5, 6].includes(result)) {
                // throw new Error(`表中星期填写有误, ${column}`)
                return -1
            }
            return result
        })()

        if (findWeekday === -1) {
            return
        }

        const weekdayRange = String(this.get('周次')).split(',')
        const result = []
        weekdayRange.forEach(iter => {
            if (iter.includes('-')) {
                // 周范围
                dataProcessing.scopeSplitting(iter, w => {
                    const res = weekly.findDate(w, findWeekday)
                    if (res) {
                        result.push(res)
                    }
                })
            } else {
                // 单独周
                const res = weekly.findDate(parseInt(iter), findWeekday)
                if (res) {
                    result.push(res)
                }
            }
        })
        // console.log(result, '--------result-');
        this.date = result.join(',')
    }

    setCustomId(id){
        this.customId = id
        return this
    }

    /**
     * 获取课程id
     */
    getCurriculumId(){
        /**
         * 将节转换为数组
         * @returns {number[]}
         */
        const getSections = () => {
            const column = this.get('节次')
            // console.log(column, '------column-');
            // const result = dataProcessing.courseSplitting(column)
            // console.log(result, '-------result--');
            // ...
            if (column) {
                return dataProcessing.courseSplitting(column)
            }
            return []
        }

        const sections = getSections()
        const resultIds = []
        // console.log('------------')
        // console.log(this.laboratoryId);
        // console.log(getSections);
        if (this.laboratoryId && sections.length > 0) {
            getAppointCourseInfo(this.laboratoryId).then(res => {
                if (res.success) {
                    if (res.data.length > 0) {
                        const curriculumList = res.data[0].curriculumList || []
                        sections.forEach(iter => {
                            const r = curriculumList.find(item => item.name === `第${iter}节`)
                            if (r) {
                                resultIds.push(r.id)
                            }
                        })
                    }
                }
                this.curriculumId = resultIds.join(',')
            })
        }
    }

    get(key){
        return this.row[this.excelRowMap.getKey(key)] || ''
    }
}

export class ExcelData {
    constructor(data, nextHandler) {
        if (!Array.isArray(data)) {
            throw Error('表格数据异常!')
        }

        const excelRowMap = new ExcelRowMap(data)
        this.data = []
        //
        new DataDict(dataDict => {
            // console.log(dataDict, '------dataDict-');
            data.forEach((iter, index) => {
                if (excelRowMap.equalsKey(iter)) {
                    this.data.push(
                        new ExcelRow(iter, excelRowMap, dataDict).setCustomId(index)
                    )
                }
            })

            nextHandler(this.data)
        })
    }
}
